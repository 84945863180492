h1 {
  font-size: xx-large;
  font-weight: bolder;
}

h2 {
  font-size: x-large;
  font-weight: bold;
}

h3 {
  font-size: large;
  font-weight: bold;
}

h4, h5, h6 {
  font-size: medium;
  font-weight: bold;
}

code {
  background-color: #1f2341;
  padding: 3px;
  border-radius: 3px;
}

pre {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #c1d2e2;
  padding: 1em;
  border-radius: 3px;
}

.hljs {
  background-color: #c1d2e2 !important;
}

ul, ol {
  list-style: disc;
  margin-left: 30px;
  padding: 0;
}

html {
  margin: 0;
  background-color: #0f1225;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #0f1225;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #1f2341;
}

*::-webkit-scrollbar-thumb:hover {
  background: #2a4158;
}

.M01 {
  color: #90a7c4;
}

.B {
  border: 1px solid #2f4150;
}

.BBT {
  border-bottom: 1px solid #2f4150;
  border-top: 1px solid #2f4150;
}

.BB {
  border-bottom: 1px solid #2f4150;
}
.BR {
  border-right: 1px solid #2f4150;
}
.BL {
  border-left: 1px solid #2f4150;
}

.M02 {
  color: #0f1225;
}

.M03 {
  color: #0f1225;
}

.M04 {
  color: #8c9ea3;
}

.B01 {
  background-color: #233649;
  transition: background-color linear 0.15s;
}

.B02 {
  background-color: #0f1225;
  transition: background-color linear 0.15s;
}

.HB01:hover {
  background-color: #2f4150;
}

.HB02:hover {
  background-color: #141831;
}

.NR {
  resize: none;
}

.Content a {
  color: #abbac9;
  transition: color linear 0.15s;
}

.Content a:hover {
  color: #71869c;
  text-decoration: underline;
}

.AB-Card {
  overflow: hidden;
  position: relative;
  z-index: 0;
  display: inline-block;
}

.AB-Card:hover::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.IMG {
  transition: all linear 0.1s;
  cursor: pointer;
}

.IMG:hover {
  filter: brightness(80%);
}

.Notifications-Scrollable {
  overflow-y: auto;
}

.Notification-Popup {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: popup cubic-bezier(1, 0.03, 0.16, 0.98) 1s 1;
}

@keyframes popup {
  from {
    bottom: -100px;
  }
  to {
    bottom: 15px;
  }
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.Green-Button {
  background-color: rgb(18, 177, 71);
  transition: background-color linear 0.1s;
}

.Red-Button {
  background-color: rgb(255, 25, 25);
  transition: background-color linear 0.1s;
}

.Green-Button:hover {
  background-color: rgb(16, 143, 58);
}

.Red-Button:hover {
  background-color: rgb(255, 10, 10);
}

.hidden {
  display: none !important;
}

.hide {
  display: none;
}

@media (min-width: 1024px) { 
  .lghide {
    display: none;
  }
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  height: 43.99px;
  border-radius: 4px;
}

.Header-Buttons {
  display: block;
}

.I-Header-Buttons {
  display: block;
}

.Inverted-Header-Buttons {
  display: none;
}

.Settings-Card {
  border-radius: 0 10px 10px 10px;
}

@media only screen and (max-width: 640px) {
  .Header-Buttons {
    display: none;
  }

  .Settings-Card {
    border-radius: 0 0 10px 10px;
  }
}

@media only screen and (max-width: 800px) {
  .Inverted-Header-Buttons {
    display: block;
  }

  .I-Header-Buttons {
    display: none;
  }
}